import { AppRegistry } from 'react-native';
import 'core-js/web/immediate';
import './web-polyfills';
import * as Sentry from '@sentry/react-native';
import App from './App';
import './web.css';
Sentry.init({
  dsn: 'https://d5ed1e032af3404abe617bbbe0216ab9@sentry.utit.dev/9',
  enableAutoSessionTracking: true,
  environment: __DEV__ ? 'dev' : 'probablyProduction'
});
AppRegistry.registerComponent('Mehrweg', () => App);
const rootTag = document.querySelector('div.container');
AppRegistry.runApplication('Mehrweg', {
  initialProps: {},
  rootTag
});
await import('./bootstrap');