import { useEffect } from 'react';
import { AbortController } from './fetch';
export const usePromise = cb => {
  useEffect(() => {
    const abortCtrl = new AbortController();
    void cb(abortCtrl.signal);
    return () => {
      abortCtrl.abort();
    };
  }, [cb]);
};

// Force re-render on locale change to ensure correct results for t() calls
export function useTranslations() {
  // noop
}