import { setSetting } from './storage';
export let API = 'https://www.replaceplastic.de/api/';
export const UPLOAD_ENDPOINT = API + 'files/';
export const API_OPTIONS = {
  Produktion: 'https://www.replaceplastic.de/api/',
  Tobias_Test: 'http://10.10.23.75:9000/api/',
  Tobias2_Test: 'http://10.10.52.100:9000/api/'
};
export function changeApi(key) {
  if (API_OPTIONS[key]) {
    API = API_OPTIONS[key];
    void setSetting('overrideApi', key);
  }
}
export function getActiveApiName() {
  for (const [key, url] of Object.entries(API_OPTIONS)) {
    if (API === url) {
      return key;
    }
  }
  return 'unknown';
}