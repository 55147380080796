/// <reference types="typescript/lib/lib.dom" />
// We have to match the API in storage-api.ts which means that we have to return Promises even though we don't use await.
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */

/* globals localStorage */
export async function clearStorage() {
  if (window.localStorage) {
    localStorage.clear();
  }
}
export async function setSetting(key, value) {
  if (window.localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
export async function getSetting(key, defaultValue) {
  if (window.localStorage) {
    try {
      const value = JSON.parse(localStorage.getItem(key) ?? 'null');
      return value === null ? defaultValue : value;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}
export async function appendScan(barcode, data) {
  if (window.localStorage) {
    try {
      let scans = JSON.parse(localStorage.getItem('scans') ?? 'null');
      if (scans === null) {
        scans = [];
      }
      scans.push({
        ...data,
        barcode: data.barcode || barcode
      });
      localStorage.setItem('scans', JSON.stringify(scans));
    } catch (e) {
      console.error(e);
    }
  }
}
export async function getScans() {
  if (window.localStorage) {
    try {
      const scans = JSON.parse(localStorage.getItem('scans') ?? 'null');
      return scans === null ? [] : scans;
    } catch (e) {
      console.error(e);
      return [];
    }
  } else {
    return [];
  }
}
export async function getScanCount() {
  return (await getScans()).length;
}
export async function deleteScan(id) {
  if (window.localStorage) {
    try {
      let scans = JSON.parse(localStorage.getItem('scans') ?? 'null');
      if (scans !== null) {
        scans = scans.filter(value => value.id !== id);
        localStorage.setItem('scans', JSON.stringify(scans));
      }
    } catch (e) {
      console.error(e);
    }
  }
}

// Noop; There's no point in caching the payloads in localStorage since the browser already
// caches the file we access to update the payloads.
export async function getAllPayloads() {
  return {};
}
export async function getPayload(_) {
  return null;
}
export async function getLastPayloadUpdate(_) {
  return null;
}
export async function updatePayload(_a, _b) {}
export async function deletePayload(_) {}