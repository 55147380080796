import './intl-polyfill';
/*
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-datetimeformat/polyfill';
*/

import { init } from './lib/translations';

/*
// @ts-expect-error The polyfilled property is added by the polyfill we load above
if (Intl.PluralRules.polyfilled) {
  require('@formatjs/intl-pluralrules/locale-data/de');
}

// @ts-expect-error The polyfilled property is added by the polyfill we load above
if (Intl.NumberFormat.polyfilled) {
  require('@formatjs/intl-numberformat/locale-data/de');
}

// @ts-expect-error The polyfilled property is added by the polyfill we load above
if (Intl.DateTimeFormat.polyfilled) {
  require('@formatjs/intl-datetimeformat/locale-data/de');
  require('@formatjs/intl-datetimeformat/add-golden-tz');
}
*/

init();