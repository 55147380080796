import { useState, useEffect } from 'react';
import { InteractionManager } from 'react-native';
import { createIntl, createIntlCache } from 'react-intl';
import * as Sentry from '@sentry/react-native';
import { API } from './constants';
import { getSetting, setSetting, getLastPayloadUpdate, getPayload, updatePayload } from './storage';
import { messages as defaultTranslations } from '../../locales/de/messages.js';
const cache = createIntlCache();
export let intl = createIntl({
  locale: 'de',
  defaultLocale: 'de'
}, cache);
const changeListeners = [];
export function onLocaleChange(cb) {
  const idx = changeListeners.length;
  changeListeners.push(cb);
  return () => {
    changeListeners.splice(idx, 1);
  };
}
export function useIntl() {
  const [ref, setRef] = useState(intl);
  useEffect(() => {
    return onLocaleChange(() => {
      setRef(intl);
    });
  }, []);
  return ref;
}
export async function updateTranslations() {
  let lastMarker = parseInt(await getSetting('lastTranslationUpdate', '-1'), 10);
  const messages = await getPayload('translations-de');
  Sentry.setContext('translationInfo', {
    lastMarker
  });
  if (Object.keys(messages ?? {}).length < 1 && lastMarker !== -1) {
    console.warn('Lost translations somewhere!');
    // Sentry.captureMessage('Lost translations somewhere!');
    lastMarker = -1;
  }
  if (isNaN(lastMarker)) {
    lastMarker = -1;
    console.error('Invalid marker stored for translation!');
    Sentry.captureMessage('Invalid marker stored for translation!');
  }
  if (lastMarker === -1) {
    // TODO: Fetch build date
    lastMarker = 10;
  }
  try {
    const response = await fetch(`${API}translations/sync/de/${lastMarker}`);
    const data = await response.json();
    if (data.success) {
      const payload = Object.assign({}, messages);
      for (const {
        key,
        message
      } of data?.messages ?? []) {
        if (key in defaultTranslations) {
          payload[key] = message;
        }
      }
      await updatePayload('translations-de', payload);
      await setSetting('lastTranslationUpdate', data.endMarker ?? Date.now());
      void loadTranslations(payload);
    } else {
      Sentry.captureMessage(data?.error ?? 'Unkown error occurred during translation fetching', {
        level: Sentry.Severity.Error
      });
      console.error(data?.error ?? data);
    }
  } catch (err) {
    Sentry.captureException(err);
    console.error('Failed to load translations', err);
  }
  Sentry.setContext('translationInfo', null);
}

// TODO: Proper fix. This is a bandaid
function fixTranslations(messages) {
  for (const [k, v] of Object.entries(messages)) {
    messages[k] = v.replace(/\n\s+/g, ' ');
  }
  return messages;
}
async function loadTranslations(payload) {
  try {
    let messages = payload ?? (await getPayload('translations-de'));
    messages = Object.assign({}, defaultTranslations, messages);
    if (Object.keys(messages ?? {}).length < 1) {
      // Sentry.captureMessage('No translations found');
      messages = fixTranslations(defaultTranslations);
    } else {
      messages = fixTranslations(messages);
    }
    intl = createIntl({
      locale: 'de',
      defaultLocale: 'de',
      messages,
      onError: err => {
        Sentry.captureException(err);
        console.error(err);
      }
    }, cache);
    for (const cb of changeListeners) {
      cb();
    }
    console.log('de translations activated');
  } catch (err) {
    Sentry.captureException(err);
    console.error('Failed to load translations from storage', err);
  }
}
export function init() {
  void loadTranslations();
  void InteractionManager.runAfterInteractions(async () => {
    const lastUpdate = await getLastPayloadUpdate('translations-de');
    if (!lastUpdate || lastUpdate < Date.now() - 24 * 3600) {
      void updateTranslations();
    }

    // TODO: Consider native timers instead
    // setInterval(() => void updateTranslations(), 24 * 3600);
  });
}