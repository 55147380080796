/*eslint-disable*/export const marker = "todo";
export const messages = {
  "4dZl3L": "Listungswünsche:",
  "AboutUs.Yyf01": "Jetzt loslegen und Produktverpackungen scannen",
  "AboutUs.fI8sf": "Über ReplacePlastic",
  "AboutUs.gEnyk": "Lizenz",
  "AltRequest.92Zh5": "Anbieter:",
  "AltRequest.96irg2": "Sehr geehrte Damen und Herren,\\n ich wende mich heute an Sie, weil ich dieses oder ein gleichartiges plastikfrei oder mit einer Mehrweglösung verpackte Produkt in Ihrem Markt kaufen können möchte:\\n * {barcode} {name}\\n \\n Plastikmüll in den Meeren stellt ein großes Problem dar, weshalb immer mehr Verbraucher ein Bewusstsein für dieses Thema zeigen. Viele Menschen wünschen sich plastikfreie Verpackungen. Auch ich gehöre dazu. Aus diesem Grund sende ich Ihnen heute den Wunsch, ein solche Produkte in Ihrem Markt kaufen können.\\n Ich hoffe, dass diese Information über die Wünsche und Werte Ihrer Zielgruppe für Sie hilfreich ist, um bessere Lösungen für Ihre Kunden zu verwirklichen.\\n Mit freundlichen Grüßen, Ihr Kunde",
  "AltRequest.9UxLG": "Name:",
  "AltRequest.EFd1B": "Land:",
  "AltRequest.K_njK": "Postleitzahl",
  "AltRequest.RDoAM": "Markt",
  "AltRequest.SyhNW": "Alternativwunsch absenden",
  "AltRequest.Ta-wW": "Dein Supermarkt ist nicht dabei?",
  "AltRequest.ThUiv": "Markt:",
  "AltRequest.cIK6H": "Selbst eine Mail an ein Unternehmen senden",
  "AltRequest.hJ9Mu": "Gleichzeitig senden wir deinen Wunsch als ein positives Feedback an das produzierende Unternehmen. Es ist wichtig, dass Unternehmen, die sich die Plastikfrage zu Herzen nehmen, erfahren, dass ihre Bemühungen von uns gesehen werden. Wir hoffen, dass diese Informationen dem Unternehmen bei Verhandlungen mit den Handelsketten helfen können.",
  "AltRequest.iIs2C": "Postleitzahl (optional) :",
  "AltRequest.sfvhn": "Land",
  "AltRequest.tyc33": "Wir glauben, dass es wichtig ist, den großen Handelsketten mitzuteilen, dass wir uns plastikfrei verpackte Produkte in ihrem Sortiment wünschen. Hier kannst du deinen Wunsch für ein gewähltes Produkt an eine der ausgewählten Ketten senden. Wir sammeln die Wünsche 14 Tage lang und senden sie dann gebündelt an das Handelsunternehmen.",
  "AltRequest.uM4KJ": "Barcode:",
  "Alternatives.0wgWn": "Kategorie:",
  "Alternatives.3EIEK": "Listungswunsch senden",
  "Alternatives.Dm_Pm": "Alternative Produktverpackungen",
  "Alternatives.IJU-D": "Rezept bei Smarticular ansehen",
  "Alternatives.J0fTf": "Hinweis: Wir machen damit keine Aussage über das Produkt an sich. Die Reihenfolge der Produkte ist zufällig und nicht vollständig.",
  "Alternatives.XYs5m": "Anbieter:",
  "Alternatives.Zn8X2": "Name:",
  "Alternatives._r7oS": "Eine weitere Produktverpackung scannen",
  "Alternatives.cE-94": "Es gibt bei unseren Partnern von Smarticular ein Rezept, wie du dir dieses Produkt auch selbst herstellen kannst.",
  "Alternatives.pC78z": "Hier findest du alternativ verpackte Produkte, die uns in dieser Kategorie bekannt sind.",
  "Camera.0xlFb": "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
  "Camera.AEL6z": "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es später erneut.",
  "Camera.IP_GG": "Taschenlampe",
  "Camera.QsnL3": "Anfrage fehlgeschlagen",
  "Camera.UXEvh": "Entschuldigung",
  "Camera.t-3BZ": "Abbrechen",
  "CameraWidget.5BAoL": "Die App hat keinen Zugriff auf die Kamera. Ohne Kamerazugriff können wir keinen Barcode scannen. Bitte versuchen Sie es erneut.",
  "CameraWidget.C2GXj": "Es ist ein unbekannter Fehler aufgetreten. Bitte starte die App neu und versuche es später erneut.",
  "CameraWidget.W_-yd": "Fehler",
  "CameraWidget.rPrCS": "Das Scannen der Barcodes ist fehlgeschlagen. Der Fehler wurde protokolliert und wir werden uns bemühen ihn zu beheben.",
  "CameraWidget.web.Nz-zO": "Kamerafehler",
  "CameraWidget.web.T-lmf": "Kamerafehler",
  "CameraWidget.web.TEckx": "Der Browser hat den Zugriff auf die Kamera verweigert.",
  "CameraWidget.web.Y1r3G": "Es konnte keine Kamera gefunden werden.",
  "CameraWidget.zSqG-": "Wir konnten auf die Kamera nicht zugreifen. Dein Gerät wird möglicherweise nicht unterstützt. Bitte melde dich bei uns für weiter Informationen.",
  "Inputs.0V3XG": "$'{path}' muss eine gültige UUID enthalten",
  "Inputs.AtiCQ": "$'{path}' darf keinen der folgenden Werte enthalten: $'{values}'",
  "Inputs.Cev0H": "Falls \"null\" als leerer Wert beabsichtigt ist, signalisiere dies bitte mit '.nullable()'",
  "Inputs.EgCEk": "Das Formular konnte nicht abgesendet werden, da die folgenden Felder nicht korrekt ausgefüllt wurden:",
  "Inputs.FtfFU": "$'{path}' darf nur aus Kleinbuchstaben bestehen",
  "Inputs.GHB-U": "$'{path}' ist ein Pflichtfeld",
  "Inputs.IlmoM": "(umgewandelt von dem Wert `{original}`).",
  "Inputs.N91kV": "$'{path}' darf nur aus Großbuchstaben bestehen",
  "Inputs.Pbe3m": "$'{path}' muss mindestens $'{min}' Zeichen enthalten",
  "Inputs.Pi8km": "$'{path}' muss einen der folgenden Werte enthalten: $'{values}'",
  "Inputs.TJDlU": "$'{path}' darf maximal $'{max}' Zeichen enthalten",
  "Inputs.TXc83": "Fehler",
  "Inputs.ZbJcR": "{path} muss ein `{type}`-Typ sein, aber der finale Wert war: `{value}`{extra}",
  "Inputs.ZpCZO": "$'{path}' muss eine gekürzter Text sein",
  "Inputs._RkNJ": "$'{path}' muss eine gültige E-Mail Adresse enthalten",
  "Inputs.g5LgE": "$'{path}' ist ungültig",
  "Inputs.hmW3t": "$'{path}' muss exakt $'{length}' Zeichen enthalten",
  "Inputs.llGa4": "Das Formular konnte nicht abgesendet werden, da das folgende Feld nicht korrekt ausgefüllt wurde:",
  "Inputs.mmS_U": "$'{path}' muss dem folgenden Außdruck entsprechen: \"$'{regex}'\"",
  "Inputs.yC3rS": "$'{path}' muss eine gültige URL enthalten",
  "IntroSlider.2Ox4b": "Darum gehts:",
  "IntroSlider.FveWF": "Willkommen bei ReplacePlastic",
  "IntroSlider.Ng8cl": "Mit dieser App scannst du die Barcodes von Produkten, die du verwenden oder kaufen würdest, um dem Anbieter mitzuteilen, dass du dir dafür Verpackungen ohne Plastik wünschst.",
  "IntroSlider.Tk5s3": "Das Feedback wird von uns weitergeleitet.",
  "IntroSlider.WIziN": "Okay, los gehts",
  "IntroSlider.Wgqng": "Hersteller und Anbieter sagen: \"Der Verbraucher will es so.\" Das sehen wir anders.",
  "IntroSlider.hNP_o": "Plastikmüll im Meer verursacht enorme Probleme. Ein großer Teil gelangt vom Land her über die Flüsse ins Meer.",
  "IntroSlider.ktNlV": "Es gibt zu viele Plastikverpackungen für Produkte des täglichen Bedarfs.",
  "IntroSlider.yUYgE": "Verpackungen spielen dabei eine große Rolle.",
  "Layout.-E4cx": "Zurück zum vorherigen Bildschirm",
  "Layout.BDYWI": "Das ist leider schiefgegangen.",
  "Layout.GpcwP": "Auch als App verfügbar:",
  "Layout.ICXLZ": "Gefördert durch:",
  "Layout.gUhu1": "Wir werden dieses Problem so schnell wie möglich beheben.",
  "Layout.pWLYS": "Es ist ein unvorhergesehener Fehler aufgetreten wodurch dieser Bildschirm nicht angezeigt werden kann.",
  "Layout.xnRAx": "Umsetzung:",
  "NoPlasticPhotoProof.07ese": "Das ist leider schiefgegangen. Bitte versuche es erneut.",
  "NoPlasticPhotoProof.0fE-b": "Fehler",
  "NoPlasticPhotoProof.ESAh4": "Verpackung fotografieren",
  "NoPlasticPhotoProof.EYj6x": "Wir können die Verpackung leider nicht fotografieren, wenn du uns nicht die Berechtigung erteiltst.",
  "NoPlasticPhotoProof.F-BFF": "Zu der Verpackung dieses Produktes gibt es unterschiedliche Angaben. Du kannst uns helfen, indem du uns ein Foto von dem Plastikanteil der Verpackung sendest.",
  "NoPlasticPhotoProof.K3GCX": "Deine Nachricht an uns (optional)",
  "NoPlasticPhotoProof.MEKvu": "Es ist ein interner Fehler aufgetreten. Wir werden uns bemühen den Fehler in Zukunft zu beheben.",
  "NoPlasticPhotoProof.OlB5N": "Senden und weiter",
  "NoPlasticPhotoProof.TxvIF": "Klärung notwendig",
  "NoPlasticPhotoProof.WnrQC": "Es wurde keine Kamera gefunden. Falls dies ein Irrtum ist, wende dich bitte an uns.",
  "NoPlasticPhotoProof.web.0qdxF": "Zu der Verpackung dieses Produktes gibt es unterschiedliche Angaben. Du kannst uns helfen, indem du uns ein Foto von dem Plastikanteil der Verpackung sendest.",
  "NoPlasticPhotoProof.web.ZS6Es": "Senden und weiter",
  "NoPlasticPhotoProof.web.yclHN": "Verpackung fotografieren",
  "NoPlasticPhotoProof.zUYwg": "Bitte wähle ein Bild aus.",
  "NoPlasticPhotoProofSubmit.56Pli": "Es ist ein unbekannter Fehler aufgetreten. Der Fehler wurde protokolliert und wir werden uns bemühen ihn zu beheben.",
  "NoPlasticPhotoProofSubmit.5ZrDb": "Eine weitere Produktverpackung scannen",
  "NoPlasticPhotoProofSubmit.7yk9-": "Eine weitere Produktverpackung scannen",
  "NoPlasticPhotoProofSubmit.9Fr39": "Es fehlen wichtige Daten. Ihre Anfrage kann leider nicht gesendet werden.",
  "NoPlasticPhotoProofSubmit.9N_K3": "Deine Einsendung hilft uns, den Status dieses Produktes bezüglich des Plastikgehalts der Verpackung zu klären.",
  "NoPlasticPhotoProofSubmit.EoQu2": "Interner Fehler",
  "NoPlasticPhotoProofSubmit.Hmj0n": "Es ist ein interner Fehler aufgetreten. Bitte starte die App neu und versuche es noch einmal.",
  "NoPlasticPhotoProofSubmit.RsyGm": "Die Anfrage konnte leider nicht abgeschlossen werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
  "NoPlasticPhotoProofSubmit.SLXsy": "Das ging leider schief...",
  "NoPlasticPhotoProofSubmit.VYBQY": "Es ist ein unbekannter Fehler aufgetreten",
  "NoPlasticPhotoProofSubmit.VpZgw": "Die Anfrage wurde nicht korrekt verarbeitet. Bitte versuchen Sie es später noch einmal.",
  "NoPlasticPhotoProofSubmit.ZX6IB": "Fehler",
  "NoPlasticPhotoProofSubmit.dMQM_": "Der Upload ist fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
  "NoPlasticPhotoProofSubmit.k-Q6I": "Das Foto ist verloren gegangen. Bitte gehe zurück und mach ein neues Foto.",
  "NoPlasticPhotoProofSubmit.lH39-": "Bitte warten...",
  "NoPlasticPhotoProofSubmit.ntJFm": "Abbrechen",
  "NoPlasticPhotoProofSubmit.rU_oO": "Danke für deine Unterstützung",
  "NoPlasticPhotoProofSubmit.yss0q": "Erneut versuchen",
  "Scan.7350x": "Barcode suchen",
  "Scan.7dlvK": "Andersmacher",
  "Scan.K_1uM": "Der eingegebene Barcode ist keine gültige Europäische Artikel Nummer (EAN). Entweder liegt ein Tippfehler vor oder die Bearbeitung dieser Produktverpackung ist nicht möglich.",
  "Scan.Qtybs": "Fehler",
  "Scan.XA7bH": "... oder selber den Barcode eingeben:",
  "Scan.dC_A_": "Produktverpackung scannen",
  "Scan.ea7EC": "Statistiken ansehen",
  "Scan.jgj-h": "Unternehmen, die sich das Plastikproblem zu Herzen genommen haben, finden Sie auf unserer Website <link>www.kueste-gegen-plastik.de</link>.",
  "Scan.mFhgU": "Barcode-Nummer",
  "ScanHistory.2ym9p": "Jetzt loslegen und Produktverpackungen scannen",
  "ScanHistory.R3h7L": "Name:",
  "ScanHistory.U19UA": "Deine Scans konnten leider nicht geladen werden",
  "ScanHistory.ULEvA": "Hersteller:",
  "ScanHistory.VJcW9": "Du hast bereits {scans, plural, =1 {# Produktverpackung} other {# Produktverpackungen}} eingesendet:",
  "ScanHistory.ftJMu": "Du hast bisher noch keine Produktverpackungen eingesendet",
  "ScanHistory.otRiN": "Sobald du Barcodes gescannt und eingesendet hast, tauchen die zugehörigen Produkte hier auf.",
  "ScanHistory.vmmIq": "Barcode:",
  "ScanHistory.z_xSB": "Lade Daten...",
  "ScanResult.-X8FD": "Sie haben diese Produktverpackung bereits gescannt.",
  "ScanResult.4tZey": "Die Verpackungsinfos konnten leider nicht abgerufen werden. Bitte stellen Sie sicher, dass Ihre Internetverbindung funktioniert und versuchen Sie es noch einmal.",
  "ScanResult.54VWl": "Dieser Hersteller/Anbieter wünscht eine persönliche Kontaktaufnahme durch Kunden.",
  "ScanResult.Dvo43": "Ich wünsche mir dieses Produkt in einer Verpackung ohne Plastik / mit weniger Plastik:",
  "ScanResult.IDIAi": "Ich wünsche mir eine Mehrweglösung (optional)",
  "ScanResult.IpjcK": "Anbieter:",
  "ScanResult.J6Xn0": "Zusatzinfos anzeigen",
  "ScanResult.Nkz1J": "Das ging leider schief...",
  "ScanResult.NyTAL": "Name:",
  "ScanResult.Toiqp": "Das wünsche ich mir in meinem Markt",
  "ScanResult.V18J-": "Unterstützung nötig!",
  "ScanResult.Vwl56": "Bitte melden Sie sich bei Interesse unter:",
  "ScanResult.XM0VX": "Zurück",
  "ScanResult.Y71yw": "Die Produktdaten werden geladen...",
  "ScanResult.ZxuSO": "Barcode:",
  "ScanResult.o1kJe": "Alternative Produktverpackung",
  "ScanResult.t9HNO": "Bitte warten...",
  "ScanResult.vVWLi": "Verbesserungswunsch senden",
  "ScanResult.ztJV9": "Entschuldigung",
  "Settings.0-Q7l": "Einstellungen",
  "Settings.Ax3Q5": "Das Deaktivieren der Push-Nachrichten ist aufgrund eines internen Fehlers fehlgeschlagen. Bitte versuchen Sie es später erneut.",
  "Settings.CGEKo": "Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.",
  "Settings.Em57D": "Die Push-Nachrichten wurden erfolgreich deaktiviert.",
  "Settings.LHxWI": "Die Push-Nachrichten konnten nicht aktiviert werden. Falls Sie es erneut probieren möchten, können Sie entweder erneut auf den Knopf drücken oder später die Benachrichtigungen über die Einstellungen aktivieren.",
  "Settings.Xoqeg": "Projekt-Updates als Push-Nachricht erhalten",
  "Settings.knn23": "Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten. Versichern Sie sich, dass Sie die neuste Version der App installiert haben und versuchen Sie es erneut.",
  "Settings.mptPK": "Es ist ein unbekannter Fehler aufgetreten. Versichern Sie sich, dass Sie die aktuellste Version der App haben und Ihre Internetverbindung funktioniert.",
  "Settings.tTMjP": "Die Push-Nachrichten wurden erfolgreich aktiviert. Sie können diese jederzeit über die Einstellungen wieder deaktivieren.",
  "Statistics.5L_cN": "An diese Anbieter wird viel Feedback gesendet:",
  "Statistics.Akj7D": "Zahlen, Daten, Fakten",
  "Statistics.ChQrL": "Produktverpackungen:",
  "Statistics.O0UXu": "Anbieter verzeichnet:",
  "Statistics.U0Xtx": "Erneut versuchen",
  "Statistics.WL_Fs": "Produkte verzeichnet:",
  "Statistics._mq7w": "Anbieter:",
  "Statistics.i6UNO": "Die Informationen konnten leider nicht geladen werden. Dies kann passieren, wenn momentan keine Internetverbindung besteht oder die Website von ReplacePlastic nicht erreichbar ist.",
  "Statistics.ibKq4": "Mails versendet:",
  "Statistics.jt0zE": "Produktverpackungen",
  "Statistics.lvUm5": "An:",
  "Statistics.p3sS4": "Kürzlich eingereichte Produktverpackungen",
  "Statistics.vgg31": "Kürzlich versandte Mails",
  "Statistics.y9-Sx": "Barcode:",
  "Statistics.z0sA2": "Verpackungen gescannt:",
  "SubmitAltRequest.A_50L": "Danke für dein Feedback",
  "SubmitAltRequest.BC1rQ": "Gemeinsam schaffen wir eine spürbare Nachfrage nach plastikfreien Verpackungen, die auch bei den Handelsketten ankommt. Wir geben deinen Wunsch an das Unternehmen weiter. Es wird in der Regel alle 14 Tage über die Kundenwünsche benachrichtigt.",
  "SubmitAltRequest.LL_w-": "Zurück zu den Alternativen",
  "SubmitAltRequest.VHx2P": "Eine andere Produktverpackung scannen",
  "SubmitAltRequest.YYMGL": "Fehler",
  "SubmitAltRequest.gL_Nz": "Es ist ein Fehler aufgetreten.",
  "SubmitAltRequest.wYYEo": "Erneut versuchen",
  "SubmitAltRequest.xITwF": "Danke für dein Feedback",
  "SubmitResult.0ltiJ": "Fast geschafft...",
  "SubmitResult.5LgIL": "Projekt-Updates als Push-Nachricht erhalten",
  "SubmitResult.BLsOk": "Das ist leider schiefgegangen...",
  "SubmitResult.CfB_f": "Eine andere Produktverpackung scannen",
  "SubmitResult.JLHeE": "In dieser Kategorie sind alternative Verpackungen bekannt.",
  "SubmitResult.LFN7U": "Möchtest du auf dem Laufenden bleiben?",
  "SubmitResult.MXwSZ": "Eine andere Produktverpackung scannen",
  "SubmitResult.Qk4Eb": "Danke für dein Feedback!",
  "SubmitResult.TXqHg": "Alternativen anzeigen",
  "SubmitResult.UZ1Mo": "Erneut versuchen",
  "SubmitResult.WLfRk": "Gemeinsam schaffen wir eine spürbare Nachfrage nach plastikfreien Verpackungen, die auch bei den Anbietern und Herstellern ankommt. Wir geben deinen Wunsch an den Anbieter weiter. Er wird in der Regel nach vier Wochen benachrichtigt, oder wenn 20 weitere Personen dieses Produkt mit der ReplacePlastic-App eingesendet haben.",
  "SubmitResult.XOiwF": "Du hast diese Produktverpackung bereits gescannt.",
  "SubmitResult.Ykdfq": "Gemeinsam schaffen wir eine spürbare Nachfrage nach plastikfreien Verpackungen, die auch bei den Anbietern und Herstellern ankommt.",
  "SubmitResult.beP0q": "Danke für dein Feedback!",
  "SubmitResult.f0zkT": "Erneut versuchen",
  "SubmitResult.fzFKT": "Dein Feedback konnte leider nicht gesendet werden. Bitte überprüfe deine Internetverbindung und probiere es erneut.",
  "SubmitResult.h1gRQ": "Du hast jetzt {scans, number} Produktverpackungen eingesendet. Danke für dein Engagement! Gemeinsam bewegen wir was! Wenn du über Entwicklungen und Erfolge aus der ReplacePlastic-Kampagne informiert werden möchtest, können wir dich über die Push-Nachrichten auf dem Laufenden halten. Das wird maximal einmal pro Monat geschehen. Du kannst die Benachrichtigungen jederzeit über die Einstellungen ändern.",
  "SubmitResult.hK8XM": "Eine andere Produktverpackung scannen",
  "ThankYou.-4pzA": "Dankeschön",
  "UserinfoForm.0e-Qx": "Die Informationen konnten leider nicht geladen werden. Dies kann passieren, wenn momentan keine Internetverbindung besteht oder die Website von ReplacePlastic nicht erreichbar ist.",
  "UserinfoForm.3sokJ": "Herstellername wie auf der Verpackung angegeben",
  "UserinfoForm.6Om39": "z.B. 150g",
  "UserinfoForm.AZzSi": "Kategorie (optional)",
  "UserinfoForm.D8iFW": "Doch nur Barcode senden",
  "UserinfoForm.J9eKI": "Menge (optional)",
  "UserinfoForm.K5iR-": "Erneut versuchen",
  "UserinfoForm.LWwXd": "z.B. Käse",
  "UserinfoForm.OzoqY": "Marke / Hersteller (optional)",
  "UserinfoForm.SQunl": "Oha! Lieber doch nur den Barcode senden",
  "UserinfoForm._dkEI": "Produktdaten jetzt senden",
  "UserinfoForm.d6leA": "Produktdaten eingeben",
  "UserinfoForm.dJGNY": "Sende Daten...",
  "UserinfoForm.lg95i": "Produktname wie auf der Verpackung angegeben",
  "UserinfoForm.sA3pK": "Produktname (optional)",
  "UserinfoForm.yC68W": "Dein Hinweis / Nachricht an uns (optional)",
  "UserinfoPrompt.4YmkQ": "Fehler",
  "UserinfoPrompt.LS9FY": "Bitte wählen Sie eine Option aus!",
  "UserinfoPrompt.Nxj_K": "Diese Produktverpackung ist leider noch nicht in unserer Datenbank. Das macht aber nichts... Du kannst uns helfen, indem du uns Informationen dazu sendest. Wenn du das nicht möchtest, können wir selbst versuchen, das Produkt über die Barcode-Nummer zu finden. Falls das gelingt, wird dein Wunsch an den Anbieter gesendet.",
  "UserinfoPrompt.OGtUL": "Barcode:",
  "UserinfoPrompt.QB5Sr": "das Produkt wegen seiner Plastikverpackung einsenden",
  "UserinfoPrompt.Uh_um": "Ich möchte",
  "UserinfoPrompt.X1k_-": "das Produkt als besser verpackte Alternative empfehlen",
  "UserinfoPrompt.lmmxK": "Ich möchte Produktdaten eingeben",
  "UserinfoPrompt.mExXM": "Ich möchte nur den Barcode senden",
  "UserinfoPrompt.ns-dI": "Ich möchte mithelfen:",
  "UserinfoPromptPhotoWidget.0ZkqA": "Bitte sende auch ein Foto der Verpackung. Dieser Schritt ist optional.",
  "UserinfoPromptPhotoWidget.6Wm31": "Verpackung fotografieren",
  "UserinfoPromptPhotoWidget.aWyyN": "Fehler",
  "WrongInfo.CSdAo": "mailto:replaceplastic@kueste-gegen-plastik.de?subject=[Produktinfos] Korrektur für Produkt {ean}",
  "WrongInfo.KOB-X": "Da stimmt was nicht...",
  "WrongInfo.NWlfE": "Die Angaben zu diesem Produkt sind falsch oder haben sich verändert? Schreib uns eine Nachricht an replaceplastic@kueste-gegen-plastik.de. Wir kümmern uns so schnell wie möglich darum.",
  "WrongInfo.Rlhou": "E-Mail schreiben",
  "WrongInfo.YKVzP": "Fehler",
  "WrongInfo.bM5ai": "Ihr Mailprogramm konnte leider nicht geöffnet werden. Bitte stellen Sie sicher, dass ein Mail-Programm für mailto: Links eingerichtet ist.",
  "WrongInfo.egLR0": "Fehlerhafte Angaben?",
  "WrongInfo.s5kpG": "Schließen",
  "WrongInfoProof.07ese": "Das ist leider schiefgegangen. Bitte versuche es erneut.",
  "WrongInfoProof.0fE-b": "Fehler",
  "WrongInfoProof.ESAh4": "Verpackung fotografieren",
  "WrongInfoProof.EYj6x": "Wir können die Verpackung leider nicht fotografieren, wenn du uns nicht die Berechtigung erteiltst.",
  "WrongInfoProof.F-BFF": "Sind die erfassten Daten zu diesem Produkt nicht korrekt? Du kannst uns helfen, indem du uns deinen Korrekturvorschlag und ein aussagekräftiges Foto sendest. Vielen Dank, dass du uns unterstützt.",
  "WrongInfoProof.K3GCX": "Deine Nachricht an uns (optional)",
  "WrongInfoProof.MEKvu": "Es ist ein interner Fehler aufgetreten. Wir werden uns bemühen den Fehler in Zukunft zu beheben.",
  "WrongInfoProof.OlB5N": "Senden und weiter",
  "WrongInfoProof.TxvIF": "Klärung notwendig",
  "WrongInfoProof.WnrQC": "Es wurde keine Kamera gefunden. Falls dies ein Irrtum ist, wende dich bitte an uns.",
  "WrongInfoProof.wj0tW": "Danke für deine Unterstützung. Deine Einsendung hilft uns, den Status dieses Produkts zu klären.",
  "WrongInfoProof.zUYwg": "Bitte wähle ein Bild aus.",
  "alert.web.5uPzZ": "Schließen",
  "wY/aJo": "Mehrwegwünsche:"
};
