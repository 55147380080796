'use strict';

const calculateChecksum = gtin => {
  gtin = parseInt(gtin, 10).toString();
  const chunks = gtin.split('').map(num => parseInt(num, 10)).reverse();
  let checksum = 0;

  // Remove first chuck (checksum)
  chunks.shift();

  // sum numbers and multiply accordingly
  chunks.forEach(function (number, index) {
    checksum += index % 2 === 1 ? number : number * 3;
  });

  // calc checksum
  checksum %= 10;
  checksum = checksum === 0 ? 0 : 10 - checksum;
  return checksum;
};
const regex = /^[0-9]+$/;
const validate = barcode => {
  if (!(barcode.length === 8 || barcode.length === 13) || regex.exec(barcode) === null) {
    return false;
  }
  const checksum = parseInt(barcode.substring(barcode.length - 1), 10);
  const calcChecksum = calculateChecksum(barcode);
  return checksum === calcChecksum;
};
export default {
  validate
};