import { Platform } from 'react-native';
export const LatoRegular = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return {
    fontFamily: 'Lato-Regular',
    fontSize
  };
};
export const LatoRegularCss = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return `
  font-family: "Lato-Regular";
  font-size: ${fontSize}px;
`;
};
export const LatoBold = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return {
    fontFamily: 'Lato-Bold',
    fontSize
  };
};
export const LatoBoldCss = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return `
  font-family: "Lato-Bold";
  font-size: ${fontSize}px;
`;
};
export const LatoItalic = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return {
    fontFamily: 'Lato-Italic',
    fontSize
  };
};
export const LatoItalicCss = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return `
  font-family: "Lato-Italic";
  font-size: ${fontSize}px;
`;
};
export function Slabo(fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  fontSize = fontSize * 1.15;
  if (Platform.OS === 'web') {
    return {
      fontFamily: '"Slabo 27px"',
      fontSize
    };
  } else {
    return {
      fontFamily: 'Slabo 27px',
      fontSize
    };
  }
}
export const SlaboCss = function (fontSize) {
  if (fontSize === void 0) {
    fontSize = 15;
  }
  return `
  font-family: "Slabo 27px";
  font-size: ${fontSize * 1.15}px;
`;
};